.chat {
  position: fixed !important;
  right: 10px;
  bottom: 0;
  z-index: 500;
  background-color: white;
  display: flex;
  flex-direction: column;
  cursor: default;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  border: 1px solid black;

  form {
    height: 100%;
  }

  &__toggler {
    background: lighten(green, 10%);
    padding: 5px;
    font-size: 18px;
    height: 50px;
    margin-top: 7px;
    &:hover, &:focus {
      background: green;
    }
  }

  &__header {
    background-color: blue;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-size: 20px;
    font-weight: 500;
    padding: 0 10px;
    height: 50px;
  }

  &__close-button {
    background: transparent;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    z-index: 2;
  }

  &__messages {
    padding: 5px;
    overflow-y: scroll;
    height: calc(100% - 100px);
    overflow-anchor: none;
  }

  &__message {
    display: block;
    margin-bottom: 10px;
    font-weight: 300;
    &--action {
      font-style: italic;
      text-align: center;
      font-size: 16px;
      line-height: 1em;
      .chat__name {
        justify-content: center;
        font-size: 14px;
      }
    }
  }

  &__name {
    display: block;
    color: lighten(black, 20%);
    font-size: 15px;
  }

  &__footer {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    border-top: 1px solid black;
  }

  &__input {
    margin-bottom: 0;
    min-height: 40px;
    width: 100%;
    .input__wrapper {
      margin: 0;
      border: none;
    }
    .input {
      margin: 0;
      resize: none;
      padding: 0 0 0 5px;
      height: 40px;
    }
    .button {
      height: 40px;
      border-radius: 3px;
      padding: 0 5px;
    }
  }
}