.settings-editor {
  height: 100vh;
  * {
    font-size: inherit;
    font-family: inherit; } }

.columns {
  display: flex; }

.column {
  position: inherit !important;
  &--editor {
    flex: 1; }
  &--content {
    flex: 2; } }
