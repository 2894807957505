@import '../../../tools/vars.scss';

.login-modal {
  max-width: 600px;

  &__signup {
    text-align: center;
    padding-top: 8px;
    color: #45565F;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    &-link {
      color: #00650A;
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
      
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }

  &__delimiter {
    position: relative;
    margin: 24px 20px 16px;
    text-align: center;
    min-height: 1px;

    &::before {
      position: absolute;
      left: 0;
      top: 50%;
      right: 0;
      height: 1px;
      background-color: #C7C7C7;
      content: '';
    }

    span {
      display: inline-block;
      background-color: #F3F3F3;
      padding: 0 4px;
      position: relative;
      z-index: 1;

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }

  &__forgot {
    text-align: center;
    margin-top: 6px;
  }

  &__forgot-link {
    color: $link-color;
    display: inline-block;
    padding: 5px;
    cursor: pointer;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__header {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #103549;
    padding-bottom: 16px;
    margin: 0 36px 16px;
    border-bottom: 1px solid #c7c7c7;
  }

  .error {
    color: red;
    text-align: center;
    margin: 8px 0;
  }

  &_log-out {
    display: flex;
    justify-content: center;
    height: 36px;
    align-items: center;
    font-family: Roboto;
    color: #6F1717;
    font-size: 25px;
  }

  .tabs {
    margin-bottom: 20px;
    & > div {
      width: 100%;
      border-radius: 0;

      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }

  .error-message {
    align-self: center;
  }

  .login {
    margin: 0 16px;
    display: flex;
    flex-direction: column;

    .checkboxes {
      margin-bottom: 10px;

      input[type="radio"] + label:before {
        margin-right: 13px;
      }

      label {
        font-weight: 300;
        font-size: 20px;
        line-height: 23px;
        color: #510000;
      }
    }

    .input-group {
      margin-bottom: 12px;

      .input {
        width: 100%;
        padding: 0;
      }

      input, select {
        display: inline-block;
        position: relative;
        width: 100%;
        height: 54px;
        padding-left: 17px;
        text-align: left;

        background: #FFFFFF;
        border: 1px solid #858585;
        box-sizing: border-box;
        border-radius: 14px;
        font-weight: 300;
        font-size: 20px;
        line-height: 23px;
        color: #000000;

      }

      label {
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 23px;
        color: #6F1717;
        display: inline-block;
        text-align: left;
        margin-bottom: 15px;
        width: 100%;
      }

      input::placeholder {
        text-align: left;
        letter-spacing: -0.38px;
        color: #9F9F9F;
        font-weight: 300;
        font-size: 20px;
        line-height: 23px;
      }
    }
    .loading {
      height: 50px;
      width: 50px;
      margin-left: auto;
    }
  }

  &_login-btn {
    button {
      margin-bottom: 10px;
      background: linear-gradient(90deg, #EC4C60 0%, #991B0E 100%);
      text-transform: capitalize !important;
    }
  }

  

  .loading-frame {
    z-index: 1201;
    backdrop-filter: blur(5px);

    img{
      width: 64px;
      height: 64px;
    }
  }

  .input._required:before {
    content: '';
    display: block;
    position: absolute;
    width: 24px;
    height: 52px;
    border-style: solid;
    border-top-right-radius: 14px;
    top: 1px;
    border-width: 0;
    right: 1px;
    background-color: #EC4C60;
    opacity: .3;
    border-bottom-right-radius: 14px;
    z-index: 1;
  }

  .input._required:after {
    content: '*';
    display: block;
    position: absolute;
    width: 24px;
    height: 54px;
    color: #6F1717;
    font-size: 18px;
    font-family: Roboto;
    line-height: 34px;
    top: 0;
    right: 0;
    text-align: center;
  }

  .ref-code__toggler {
    margin-top: 20px;
  }

  .ref-code__input {
    max-height: 0;
    min-height: 0;
    transition: .2s;
    opacity: 0;
    overflow: hidden;

    &--active {
      max-height: 100px;
      opacity: 1;
    }
  }

  .work-type__button {
    width: 100%;
    padding: 10px 20px;
    border: 1px solid #858585;
    background-color: transparent;
    border-radius: 14px;
    text-align: left;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .street {
    margin-top: 20px;
  }
}

.restore-password-block {
  display: flex;
  justify-content: center;
  height: 54px;
  align-self: flex-start;
  margin: 38px 0 0 12px;
}

.restore-password-block__button {
  &:disabled {
    background: #dadada;
    border-color: #dbdbdb;
    cursor: default;
  }
}

.sign-in__password-block {
  display: flex;
}

.password-block__input {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .buttons {
    position: absolute;
    right: 0;
  }
}

.alert-container {
  display: flex;
  justify-content: center;
}

.send_to_whatsapp_checkbox {
  margin-bottom: 40px;
}