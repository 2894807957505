@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Roboto:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;800&display=swap');
@import './tools/vars.scss';

body {
  background: #F3F3F3;
  margin: 0;
  color: $text-color;
}

a:active,
a:hover,
a {
  text-decoration: none;
  color: #666;
}

#root {
  main {
    max-width: 900px;
    margin: auto;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    height: 100%;
    min-height: 100vh;

    section {
      padding: 0 20px;
    }
  }

  .loading-frame {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    &-static {
      text-align: center;
    }

    img {
      max-width: 150px;
      max-height: 150px;
    }

    &__title {
      margin-top: 30px;
      font-size: 20px;
      font-family: "Roboto";
      font-weight: bolder;

      & + .errorIcon img {
        max-width: 150px;
        max-height: 150px;
      }
    }
  }

  .main-section {
    .loading-frame {
      height: calc(100% - 73px);
      top: 73px;
    }
  }

  textarea:focus, input:focus, button:focus, select:focus {
    outline: none;
  }

  .error-message {
    font-family: Roboto;
    font-weight: normal;
    margin: 0;
    color: red;
    font-size: 17px;
    margin-bottom: 10px;
  }

  //  custom scrollbar
  /* width */
  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.17);;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.50);;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .react-timekeeper {
    position: fixed;
    margin: 0 auto;
    /* bottom: 50%; */
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .error-message-status {
    color: red;
    font-family: Roboto;
    text-align: center;
    font-size: 20px;
    margin: 0 auto
  }

  .main__time-picker {
    position: fixed;
    display: none;
    z-index: 0;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    &._visible {
      display: block;
      z-index: 800;
    }
  }
}

img {
  cursor: pointer;
}

.disabled {
  filter: grayscale(1);
  opacity: 0.3;
  cursor: default;
}

.MuiPaper-root {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

* {
  box-sizing: border-box;
  font-family: 'Nunito Sans', sans-serif;
}

*:not(b) {
  font-weight: 300;
}

button {
  cursor: pointer;
}